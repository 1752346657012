<template>

  <div class="form-elements form-profile">
    <div class="row">
      <div class="flex xs12">
        <vac-card :title="$t('Profile')">
          <form :style="{paddingTop: '20px'}"
                class="ttl-form"
                @submit.prevent="onsubmit">

            <div class="row">

              <div class="flex md6 sm6 xs12">

                <vn-input
                  v-model="username"
                  :readonly="true"
                  placeholder="Username"
                  required
                />

              </div>

              <div class="flex md6 sm6 xs12">
                <vn-input
                  v-model="firstName"
                  placeholder="First name"
                  required
                  @focus="focus"
                />

              </div>

              <div class="flex md6 sm6 xs12">
                <vn-input
                  v-model="lastName"
                  placeholder="Lastname"
                  required
                />
              </div>

              <div class="flex md6 sm6 xs12">
                <vn-input
                  v-model="email"
                  placeholder="E-mail"
                  required
                />
              </div>

              <div class="flex md6 sm6 xs12">
                <vn-input
                  v-model="password"
                  :error="passwordError"
                  placeholder="Enter new password"
                  type="password"
                />
                <div class="input__under-text">Password must be at least 8 characters long</div>
              </div>

              <va-button class="profile-submit"
                         type="submit">
                {{ $t('Save changes') }}
              </va-button>

            </div>

          </form>
        </vac-card>
      </div>
    </div>

    <div class="row">
      <div class="flex xs12">
        <vac-card>

          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" grow style="width: 100%;">
                <va-tab
                  v-for="title in tabTitles.slice(0,3)"
                  :key="title"
                  class="toggle-tab">
                  {{ title }}
                </va-tab>
              </va-tabs>
            </div>
          </div>

          <div v-if="tabValue === 2" class="row">
            <div v-for="retailer in userRetailers"
                 v-bind:key="retailer.name"
                 class="flex">
              <div class="user-tab">{{ retailer.name }}</div>
            </div>
          </div>

          <div v-if="tabValue === 1" class="row">
            <div v-for="license in userLicenses"
                 v-bind:key="license.name"
                 class="flex">
              <div class="user-tab">{{ license.name }}</div>
            </div>
          </div>

          <div v-if="tabValue === 0" class="row">
            <div v-for="brand in userBrands"
                 v-bind:key="brand.name"
                 class="flex">
              <div class="user-tab">{{ brand.name }}</div>
            </div>
          </div>
        </vac-card>
      </div>
    </div>

    <va-button class="btn--small btn--wide"
               style="margin: 10px 0 0;" @click="logout">
      Log out
    </va-button>

    <Portal to="body">
      <the-modal v-if="isFieldsModal" @close="onCloseFieldsModal">
        <div class="the-modal__title">Changing profile information</div>
        <div class="the-modal__text">Are you sure you want to change the fields {{ changedFieldsList }}?</div>
        <div class="the-modal__buttons">
          <button class="the-modal__button the-modal__button--black" @click="onOpenFieldsConfirmModal">Yes</button>
          <button class="the-modal__button" @click="onCloseFieldsModal">No</button>
        </div>
      </the-modal>
    </Portal>
    <Portal to="body">
      <the-modal v-if="isFieldsConfirmModal" @close="onCloseFieldsConfirmModal">
        <div class="the-modal__title">Changing profile information</div>
        <div class="the-modal__text">Field {{ changedFieldsList }} was changed</div>
      </the-modal>
    </Portal>
    <Portal to="body">
      <the-modal v-if="isPasswordModal" @close="onClosePasswordModal">
        <div class="the-modal__title">Password change</div>
        <div class="the-modal__text">Are you sure you want to change your password?</div>
        <div class="the-modal__buttons">
          <button class="the-modal__button the-modal__button--black" @click="onOpenPasswordConfirmModal">Yes</button>
          <button class="the-modal__button" @click="onClosePasswordModal">No</button>
        </div>
      </the-modal>
    </Portal>
    <Portal to="body">
      <the-modal v-if="isPasswordConfirmModal" @close="onClosePasswordConfirmModal">
        <div class="the-modal__title">Password change</div>
        <div class="the-modal__text">Your password has been successfully changed</div>
      </the-modal>
    </Portal>
  </div>

</template>

<script>
import {mapActions} from 'vuex';
import {showToastError} from "@/services/Helpers/HelperToast";
import API from '../../services/API/API';
import VaButton from "vuestic-ui/src/components/vuestic-components/va-button/VaButton";
import VnInput from "../ui/vn-input";
import TheModal from "@/components/TheModal";
import {Portal} from "portal-vue";
import {sortArrayOfObject} from "@/services/Helpers/HelperObject.js";

const userInfo = JSON.parse(localStorage.getItem('ttlUserInfo'));

export default {
  name: 'Profile',
  components: {
    VaButton,
    VnInput,
    TheModal,
    Portal
  },
  data() {
    return {
      initialFirstName: userInfo.firstName,
      initialLastName: userInfo.lastName,
      initialEmail: userInfo.email,
      username: userInfo.username,
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      email: userInfo.email,
      password: '',
      userBrands: [],
      userLicenses: userInfo.licenses,
      userRetailers: userInfo.retailers,
      tabTitles: ['Connected Brands', 'Connected Licenses', 'Connected Retailers'],
      tabValue: 0,
      passwordError: false,
      passwordLabel: 'Password',
      isFieldsModal: false,
      isFieldsConfirmModal: false,
      isPasswordModal: false,
      isPasswordConfirmModal: false,
    };
  },
  async created() {
    if (userInfo.roles.includes('ROLE_ADMIN')) {
      await API.APIGet(`${process.env.VUE_APP_API_URL}/brands/all`, {}, (data) => {
        this.userBrands = data
      })
    } else {
      this.userBrands = userInfo.licenses.map(l => l.brand)
    }

  },

  computed: {
    changedFieldsList() {
      const list = []

      if (this.initialFirstName !== this.firstName)
        list.push('First name');

      if (this.initialLastName !== this.lastName)
        list.push('Last name');

      if (this.initialEmail !== this.email)
        list.push('Email');

      let listString = ""

      list.forEach(elem => {
        listString += elem + ", "
      })

      if (listString.length === 0)
        return ""
      return listString.slice(0, listString.length - 2);
    }
  },
  methods: {
    ...mapActions([
      'logout',
    ]),
    onsubmit(e) {
      e.preventDefault();
      this.errors = [];

      this.passwordError = false;
      this.passwordLabel = 'Password';

      if (this.changedFieldsList === "") {
        if (this.password.length < 8 && this.password.length !== 0) {
          this.passwordError = true;
          this.passwordLabel = 'Password must be at least 8 characters';
          return false;
        } else if (this.password.length === 0) {
          showToastError("Nothing to change", this.$toast)
        } else {
          this.isPasswordModal = true
        }
      } else {
        this.isFieldsModal = true
      }
    },
    focus() {
    },

    updateProfile() {
      const data = {};
      data.firstname = this.firstName;
      data.lastname = this.lastName;
      data.email = this.email;
      API.APIPut(`${process.env.VUE_APP_API_URL}/profile/update-self`, data, (userInfo) => {
        localStorage.setItem('ttlUserInfo', JSON.stringify(userInfo));
        if (this.password.length) {
          if (this.password.length < 8) {
            this.passwordError = true;
            this.passwordLabel = 'Password must be at least 8 characters';
            return false;
          }
          this.isPasswordModal = true
        }
      },
        () => {showToastError("Something went wrong", this.$toast)}
      );
    },

    updateProfilePassword() {
      API.APIPut(`${process.env.VUE_APP_API_URL}/profile/change-password`, {password: this.password}, () => {}, () => {
        showToastError("Something went wrong", this.$toast)
      });
    },

    onCloseFieldsModal() {
      this.isFieldsModal = false
    },
    onCloseFieldsConfirmModal() {
      this.initialFirstName = this.firstName
      this.initialLastName = this.lastName
      this.initialEmail = this.email
      this.isFieldsConfirmModal = false
    },
    onClosePasswordModal() {
      this.isPasswordModal = false
    },
    onClosePasswordConfirmModal() {
      this.isPasswordConfirmModal = false
    },

    onOpenFieldsConfirmModal() {
      this.updateProfile()
      this.isFieldsModal = false
      this.isFieldsConfirmModal = true
    },
    onOpenPasswordConfirmModal() {
      this.updateProfilePassword()
      this.isPasswordModal = false
      this.isPasswordConfirmModal = true
      this.password = ""
    },
  },
};
</script>

<style lang="scss">
.form-profile {
  input[readonly] {
    opacity: 0.4;
    cursor: not-allowed;
  }

  .flex {
    align-items: center;
  }

  .ttl-form {
    padding: 0 !important;

    .va-input-wrapper {
      margin-bottom: 0;
    }

    .va-input__container__input {
      background-color: #f1f4f8;
      border: 1px solid #f1f4f8 !important;

      &:valid {
        background-color: #f1f4f8 !important;
      }
    }
  }

  form .row {
    align-items: center;
  }

  .toggle-tab {
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    font-weight: 500;
  }

  .user-tab {
    font-size: 16px;
    line-height: 22px;
    padding: 8px 20px 10px;
    background-color: #d6dee2;
    border-radius: 24px;
  }

  .profile-submit {
    margin: -16px 0 0 0.75em;
  }
}

.input__under-text {
  margin-left: 16px;
  margin-top: 2px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #8d9ca8;
}
</style>
