<template>
  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1L4 4L7 1" stroke="#DB4545" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7 7L4 4L1 7" stroke="#DB4545" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "Cross",
};
</script>
